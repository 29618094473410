<template>
  <div>
    <Loader v-if="isLoading" />
    <st-data-table
      :items="applications"
      :fields="fields"
      :configurableFields="
        this.hasPermissionToStaffOwnApplications || this.isSuperAdmin
          ? this.configurableFields
          : []
      "
      :actions="actions"
      :modelPresenter="presenter"
      :firstColumnSelectable="assigned"
      :allowSelectAll="assigned"
      :headClass="tableHeadClass"
      :serverSideSorting="true"
      :customBooleanTypeField="true"
      @view="onView"
      @edit="onEdit"
      @delete="onDelete"
      @itemChecked="checkItems"
      @viewStatus="viewSigningStatus"
      @sendEmail="sendEmail"
      @viewQR="viewQR"
      responsive="sm"
      hover
      stateModule="applications/list"
      ref="applicationList"
      actionsClass="st-application-action-class"
      :checkLimit="appConfig.BULK_SIGN_LIMIT"
      checkLimitMessage="APPLICATION.NOTIFIERS.BULK_SIGN_LIMIT"
      persistanceIdentifier="generalApplicationsTable"
    >
    </st-data-table>
    <application-signing-status-modal ref="signing-status-modal">
    </application-signing-status-modal>
    <internal-document-signing-status-modal ref="internal-signing-status-modal">
    </internal-document-signing-status-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ApplicationModel } from "@/modules/applications/models/application-model";
import { ApplicationsPermissions } from "@/modules/applications/applications-permissions";
import Roles from "@/security/roles";
import ApplicationSigningStatusModal from "@/modules/applications/components/ApplicationSigningStatusModal.vue";
import InternalDocumentSigningStatusModal from "@/modules/internal-documents/components/InternalDocumentSigningStatusModal.vue";
import printContent from "@/shared/utils/registered-application-print";

const { fields } = ApplicationModel;

export default {
  name: "ApplicationsListTable",
  props: {
    assigned: {
      type: Boolean,
      default: false,
    },
    headClass: {
      type: String,
      default: "st-data-table-head",
    },
  },
  components: {
    ApplicationSigningStatusModal,
    InternalDocumentSigningStatusModal,
  },
  data() {
    return {
      presenter: ApplicationModel.presenter,
      isLoading: false,
      configurableFields: [
        fields.categoryType.extendField({
          customField: true,
          portalTarget: "category-usage-type-custom-field",
        }),
        fields.assigned_staff_user_name.extendField({
          customField: true,
          portalTarget: "enable-truncate",
        }),
        fields.identification_number.extendField({
          customField: true,
          portalTarget: "enable-truncate",
          sortable: true,
        }),
        fields.allocated_document_series.extendField({ sortable: true }),
        fields.application_date.extendField({ bold: true, sortable: true }),
        fields.issued_date.extendField({ bold: true, sortable: true }),
        fields.last_review_date.extendField({ bold: true, sortable: true }),
        fields.internal_status.extendField({
          labelType: true,
          sortable: true,
        }),
        fields.response_due_in_days.extendField({
          bold: true,
          sortable: true,
          customField: true,
          portalTarget: "response-days-custom-field",
        }),
        fields.application_type_name.extendField({
          customField: true,
          portalTarget: "enable-truncate",
          sortable: true,
        }),
        fields.beneficiary.extendField({
          customField: true,
          portalTarget: "beneficiary-custom-field",
        }),
        fields.locality.extendField({
          customField: true,
          portalTarget: "locality-custom-field",
        }),
        fields.created_date.extendField({ bold: true, sortable: true }),
        fields.parent_application_identification_number.extendField({
          sortable: true,
          customField: true,
          portalTarget: "enable-truncate",
          tdClass: "st-data-table-body-td",
        }),
        fields.signer_function_type.extendField({
          customField: true,
          portalTarget: "enable-truncate",
        }),
        fields.signer_next_function_type.extendField({
          customField: true,
          portalTarget: "enable-truncate",
        }),
        fields.document_series_registers.extendField({
          customField: true,
          portalTarget: "enable-truncate",
        }),
      ],
      selected: [],
      selectedApplication: {},
    };
  },
  watch: {
    resetShowAllDoc(value) {
      this.$refs.applicationList.deselectAll(value);
    },
  },
  computed: {
    ...mapGetters({
      rows: "applications/list/rows",
      currentUser: "auth/currentUser",
      resetShowAllDoc: "applications/form/resetShowAllDoc",
      isStaff: "auth/isStaff",
      appConfig: "shared/appConfig",
      logoToken: 'shared/logoToken'
    }),
    actions() {
      return [
        {
          name: "view",
          icon: "eye",
          tooltipText: this.$t("GENERAL.ACTIONS.VIEW"),
          customIcon: false,
          type: "primary",
        },
        {
          name: "edit",
          icon: "edit",
          tooltipText: this.$t("GENERAL.ACTIONS.EDIT"),
          customIcon: false,
          type: "primary",
          hideOnRow: true,
        },
        {
          name: "delete",
          tooltipText: this.$t("GENERAL.ACTIONS.DELETE"),
          icon: "trash-alt",
          customIcon: false,
          type: "danger",
          hideOnRow: true,
          customDeleteMsg: this.$t("APPLICATION.LIST.CONFIRM_DELETE_TEXT"),
        },
        {
          name: "viewStatus",
          icon: "signature",
          tooltipText: this.$t("GENERAL.ACTIONS.VIEW_STATUS"),
          customIcon: false,
          type: "primary",
          hideOnRow: true,
        },
        {
          name: "sendEmail",
          icon: "envelope",
          tooltipText: this.$t("GENERAL.ACTIONS.SEND_EMAIL"),
          customIcon: false,
          type: "primary",
          permission: this.hasPermissionToSendCircularNotifications,
        },
        {
          name: "viewQR",
          icon: "qrcode",
          tooltipText: this.$t("GENERAL.ACTIONS.VIEW_QR"),
          customIcon: false,
          type: "primary",
          hideOnRow: true,
        },
      ];
    },
    hasPermissionToStaffApplications() {
      return new ApplicationsPermissions(this.currentUser).readStaff;
    },
    hasPermissionToStaffOwnApplications() {
      return new ApplicationsPermissions(this.currentUser).readOwnStaff;
    },
    hasPermissionToReadStaff() {
      return (
        this.hasPermissionToStaffApplications ||
        this.hasPermissionToStaffOwnApplications
      );
    },
    hasPermissionToSendCircularNotifications() {
      return new ApplicationsPermissions(this.currentUser).createCircularNotifications;
    },
    isSuperAdmin() {
      return this.currentUser.user_type_name === Roles.values["super_admin"];
    },
    fields() {
      let tableFields = [];
      let selectedColumn = { label: "", key: "selected" };

      if (this.assigned) {
        tableFields.push(selectedColumn);
      }

      if (this.hasPermissionToStaffOwnApplications || this.isSuperAdmin) {
        tableFields.push(
          fields.parent_application_identification_number.extendField({
            sortable: true,
            customField: true,
            portalTarget: "enable-truncate",
            tdClass: "st-data-table-body-td",
          }),
          fields.identification_number.extendField({
            customField: true,
            portalTarget: "enable-truncate",
            sortable: true,
          }),
          fields.created_date.extendField({ bold: true, sortable: true }),
          fields.allocated_document_series.extendField({ sortable: true }),
          fields.issued_date.extendField({ bold: true, sortable: true }),
          fields.internal_status.extendField({
            labelType: true,
            sortable: true,
          }),
          fields.application_type_name.extendField({
            customField: true,
            portalTarget: "enable-truncate",
            sortable: true,
          }),
          fields.beneficiary.extendField({
            customField: true,
            portalTarget: "beneficiary-custom-field",
          })
        );
        if (this.hasPermissionToStaffApplications) {
          if (this.assigned) {
            tableFields = [
              selectedColumn,
              fields.categoryType.extendField({
                customField: true,
                portalTarget: "category-usage-type-custom-field",
              }),
              fields.assigned_staff_user_name.extendField({
                customField: true,
                portalTarget: "enable-truncate",
              }),
            ].concat(tableFields);
          } else {
            tableFields = [
              fields.categoryType.extendField({
                customField: true,
                portalTarget: "category-usage-type-custom-field",
              }),
              fields.assigned_staff_user_name.extendField({
                customField: true,
                portalTarget: "enable-truncate",
              }),
            ].concat(tableFields);
          }
        }
      } else {
        tableFields.push(
          fields.identification_number.extendField({
            sortable: true,
            customField: true,
            portalTarget: "enable-truncate",
            tdClass: "st-data-table-body-td",
          }),
          fields.allocated_document_series.extendField({ sortable: true }),
          fields.application_date.extendField({ bold: true, sortable: true }),
          fields.application_type_name.extendField({
            customField: true,
            portalTarget: "enable-truncate",
            sortable: true,
          }),
          fields.locality.extendField({
            customField: true,
            portalTarget: "locality-custom-field",
          }),
          fields.response_due_in_days.extendField({
            bold: true,
            sortable: true,
            customField: true,
            portalTarget: "response-days-custom-field"
          }),
          fields.created_date.extendField({ sortable: true }),
          fields.beneficiary.extendField({
            customField: true,
            portalTarget: "beneficiary-custom-field",
          }),
          fields.is_paid.extendField({ booleanType: true }),
          fields.is_signed.extendField({ booleanType: true }),
          fields.status.extendField({ labelType: true, sortable: true })
        );
      }

      return tableFields;
    },
    applications() {
      return this.rows.map((row) => {
        let hideAction = true;
        let hideActionName = "";
        if (
          this.isStaff &&
          this.currentUser.user_id &&
          row.assigned_staff_user_id === this.currentUser.user_id &&
          row.is_internal !== true
        ) {
          hideActionName = "edit";
        }
        if (!this.isStaff && row.status === "draft") {
          hideActionName = "delete";
        }

        if (this.isStaff) {
          if (
            (row.internal_status !== "sign_pending" && row?.to_sign_by_assigned_staff) ||
            ["sign_pending", "signed"].includes(row.internal_status)
          ) {
            hideActionName = "viewStatus";
          }
        }

        if (!row.is_internal && row.status !== "draft") {
          hideActionName = "viewQR";
        }

        return {
          ...row,
          hideAction,
          hideActionName,
        };
      });
    },
    tableHeadClass() {
      let headerClass = this.headClass;

      if (this.assigned) {
        headerClass += " first-column-check";
      }

      return headerClass;
    },
  },
  methods: {
    ...mapActions({
      selectRecord: "applications/form/find",
      doSelect: "applications/list/doSelect",
      doRemove: "applications/list/doRemove",
      getOrganisationsLogo: 'shared/getOrganisationsLogo',
      doGenerateApplicationQR: "applications/form/doGenerateApplicationQR"
    }),
    async onView(data) {
      if (!data?.item?.id) return;
      if (data.item.is_internal == true) {
        if (this.hasPermissionToStaffApplications) {
          this.$router.push({
            name: "internalDocumentEdit",
            params: {
              id: data.item.id,
            },
          });
        }
      } else {
        const application = await this.selectRecord({
          id: data.item.id,
          loadStaff: this.hasPermissionToReadStaff,
        });
        const routeName =
          application?.status === "draft"
            ? "applicationEdit"
            : "applicationView";
        this.$router.push({
          name: routeName,
          params: {
            id: data.item.id,
          },
        });
      }
    },
    onEdit(application) {
      this.$router.push({
        params: {
          id: application.item.id,
          activeTab: "listDocuments",
        },
        name: "applicationView",
      });
    },
    onDelete(application) {
      this.doRemove(application.item.id).then(() => {
        this.$emit("refreshApplicationsList");
      });
    },
    checkItems(data) {
      if (this.appConfig?.BULK_SIGN_LIMIT) {
      }
      this.doSelect(data);
    },
    async viewSigningStatus(data) {
      if (!data?.item?.id) return;
      await this.selectRecord({
        id: data.item.id,
        loadStaff: this.hasPermissionToReadStaff,
      });
      if (data.item.is_internal == true) {
        this.$refs["internal-signing-status-modal"].show();
      } else {
        this.$refs["signing-status-modal"].show();
      }
    },
    sendEmail(data) {
      if (!data?.item?.id) return;
      this.$router.push({
        name: "applicationCircularMail",
        params: {
          id: data.item.id,
        },
      });
    },
    async viewQR(application) {
      this.isLoading = true;
      try {
        const publicAppUrl = new URL(window.VUE_APP_LANDING_PAGE_URL);
        publicAppUrl.hostname = `public.${publicAppUrl.hostname}`;
        const [logos, qr] = await Promise.all([
          this.getOrganisationsLogo(),
          this.doGenerateApplicationQR({
            qr_code: `${publicAppUrl.toString()}qr/${application.item.id}`,
            size: 100,
          }),
        ]);
        let organisation_logo = "";
        const org = logos.find((l) => l.tenant_id === application.item.tenant_id);
        if (org.logo) {
          organisation_logo = `${window.VUE_APP_API_URL}/files/${org.logo}?token=${this.logoToken}`;
        }
        printContent({ ...application.item, organisation_logo, application_qr: qr });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
