function printContent(applicationData) {
    const id = applicationData?.identification_number || applicationData?.allocated_document_series;
    const created_date = applicationData?.created_date;
    // Create an iframe
    const printFrame = document.createElement('iframe');
    printFrame.style.position = 'absolute';
    printFrame.style.width = '0';
    printFrame.style.height = '0';
    printFrame.style.border = 'none';
    document.body.appendChild(printFrame);

    const doc = printFrame.contentWindow.document;
    doc.open();
    doc.write('<html><head><title>Print</title>');
    doc.write('<style>');
    doc.write('body { margin: 0; display: flex; justify-content: center; align-items: center; flex-direction: column; padding-top: 60px; text-align: center; width: 350px; margin: 0 auto; }');
    doc.write('@media print {');
    doc.write('  @page { margin: 0; }'); // Remove default page margins
    doc.write('  body { -webkit-print-color-adjust: exact; }');
    doc.write('}');
    doc.write('</style>');
    doc.write('</head><body>');

    // Add the logo and organization name, centered
    doc.write('<div style="width: 100%; display: flex; justify-content: center; align-items: center; margin-bottom: 20px;">');

    if (applicationData.organisation_logo) {
        doc.write(`<img src="${applicationData.organisation_logo}" style="width: 50px; height: auto; margin-right: 10px;" alt="Organisation Logo" />`);
    }

    doc.write(`<p style="font-size: 18px; font-weight: bold; color: #333; margin-left: 10px;">${applicationData.tenant_name}</p>`);
    doc.write('</div>');
    
    doc.write('<div>');
    doc.write('<p style="margin-bottom: 32px">Documentul dumneavoastră a fost înregistrat cu succes în Ghișeul Unic!</p>');
    doc.write(`<p>Număr document: <span style='font-weight: bold'>${id}</span></p>`);
    doc.write(`<p style="margin-bottom: 32px">Dată înregistrare: ${new Date(created_date).toLocaleDateString()}</p>`);
    if (applicationData.application_qr) {          
        doc.write('<p style="font-size: 14px; font-weight: bold">Scanează pentru status document: </p>');
        doc.write('<div style="text-align: center; margin-bottom: 16px;">');
        doc.write(`<img src="data:image/png;base64,${applicationData.application_qr}" alt="QR Code" style="width: 100px; height: 100px;" />`);
        doc.write('</div>');
    }
    doc.write('</div>');
    
    doc.write('</body></html>');
    doc.close();

    printFrame.contentWindow.onload = function() {
        printFrame.contentWindow.print();
        document.body.removeChild(printFrame);
    };
}

export { printContent as default };