<template>
  <form
    class="form"
    novalidate="novalidate"
    id="st_application_report_form"
    @submit.stop.prevent="doGenerate()"
  >
    <loader v-if="isLoading" />
    <div class="form-group">
      <label>{{ fields.name.label }} *</label>
      <st-input-select
        :name="fields.name.name"
        :placeholder="$t('APPLICATION.GENERATE_REPORT_MODAL.NAME_PLACEHOLDER')"
        :options="predefinedOptions"
        @update="onReportChanged"
        :formValidation="fv"
        :ref="fields.name"
      ></st-input-select>
    </div>
    <div class="form-group">
      <label> {{ fields.application_type_id.label }} * </label>
      <st-select
        v-model="model[fields.application_type_id.name]"
        :ref="fields.application_type_id.name"
        :field="fields.application_type_id"
        :formValidation="fv"
        searchable
        @change="onApplicationTypeChange"
      />
    </div>
    <div class="form-group">
      <label> {{ $t('APPLICATION.GENERATE_REPORT_MODAL.DATE_RANGE') }}</label>
      <st-date-range
        :ref="fields.start_date.name"
        v-model="date"
        :endMax="endMax"
        startField="start_date"
        endField="end_date"
        @change="() => {}"
        :formatOptions="dateFormatOptions"
        position="dropright"
      />
    </div>
    <div class="form-group">
      <label> {{ fields.destinations.label }}</label>
      <StDualListBox
        :source="source"
        :destination="destination"
        label="name"
        @onChangeList="onChangeList"
        :enabledValidation="true"
        :formValidation="fv"
        :validationFieldName="fields.destinations.name"
      />
    </div>
    <div class="form-group">
      <b-form-checkbox v-model="save" size="md">
        {{ $t('APPLICATION.GENERATE_REPORT_MODAL.SAVE_AS_PREDEFINED') }}
      </b-form-checkbox>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { ApplicationReportModel } from "@/modules/applications/models/application-report-model";
import { FormSchema } from "@/shared/form/form-schema";
import StDualListBox from "@/shared/components/dual-listbox/StDualListBox";
import StInputSelect from "@/shared/components/StInputSelect";
import { createFormValidation } from "@/shared/utils/create-form-validation";
import { ApplicationsPermissions } from "@/modules/applications/applications-permissions";

const { fields } = ApplicationReportModel;

const formSchema = new FormSchema([
  fields.name,
  fields.application_type_id,
  fields.start_date,
  fields.end_date,
  fields.destinations,
]);

const defaultColumns = [{ name: "status" }, { name: 'identification_number'}, { name: 'application_date' }];
const excludeColumnTypes = ['text', 'html', 'divider'];

export default {
  name: "GenerateApplicatiosReportForm",
  components: {
    StDualListBox,
    StInputSelect,
  },
  data() {
    return {
      predefinedOptions: [],
      source: [],
      destination: [],
      rules: formSchema.rules(),
      model: null,
      fields,
      fv: null,
      dateFormatOptions: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      },
      endMax: new Date(),
      date: {
        start_date: null,
        end_end: null,
      },
      save: false,
      isProcessing: false,
      isLoading: false,
    };
  },
  mounted() {
    this.fv = createFormValidation("st_application_report_form", this.rules);
  },
  async created() {
    this.model = formSchema.initialValues({});
    this.predefinedOptions = (await this.getPredefinedReports()).map((report) => {
      return {
        ...report,
        label: report.name,
      };
    });
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    hasPermissionToEditPredefinedReports() {
      return new ApplicationsPermissions(this.currentUser).editPredefinedReports;
    },
  },
  methods: {
    ...mapActions({
      doFindForm: "applications/form/findForm",
      doFindApplicationType: "applications/form/findApplicationType",
      getPredefinedReports: "applications/form/getPredefinedReports",
      savePredefinedReports: "applications/form/doSavePredefinedReport",
      updatePredefinedReports: "applications/form/doUpdatePredefinedReport",
      generateApplicationsReportCsv: "applications/form/doGenerateApplicationsReportCsv",
    }),
    async onReportChanged(report) {
      if (report && report.id) {
        this.isProcessing = true;
        const applicationTypeId = report.input_params.find(
          (param) => param.key === "application_type_id"
        ).value;
        this.model[fields.application_type_id.name] = applicationTypeId;
        await this.populateDualBoxValues(applicationTypeId);
        this.destination = report.columns.map((r) => {
          return { name: r };
        });
        this.source = this.source.filter((s) => !report.columns.includes(s.name));
        this.isProcessing = false;
      } else {
        this.model[fields.application_type_id.name] = null;
        this.source = [];
        this.destination = [];
      }
      this.model[fields.name.name] = report;
    },
    async doGenerate() {
      const validate = await this.fv.validate();
      if (validate === "Valid") {
        this.isLoading = true;
        const reportName = this.model[fields.name.name].label
          ? this.model[fields.name.name].label
          : this.model[fields.name.name];
        if (this.save) {
          const payload = {
            name: reportName,
            entity_type: "applications",
            input_params: [
              {
                key: "application_type_id",
                value: this.model[fields.application_type_id.name],
              },
            ],
            columns: this.destination.map((d) => d.name),
          };
          if (this.model[fields.name.name].id) {
            await this.updatePredefinedReports({
              payload,
              id: this.model[fields.name.name].id,
            });
          } else {
            await this.savePredefinedReports(payload);
          }
        }

        const csvResponse = await this.generateApplicationsReportCsv({
          name: reportName,
          entity_type: "applications",
          start_date: this.date.start_date,
          end_date: this.date.end_date,
          application_type_id: this.model[fields.application_type_id.name],
          columns: this.destination.map((d) => d.name),
        });

        const blob = new Blob([csvResponse], { type: "text/csv" });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = `${reportName}_${this.date.start_date}-${this.date.end_date}.csv`;
        link.click();
        window.URL.revokeObjectURL(downloadUrl);
        this.isLoading = false;
        return true;
      } else {
        return false;
      }
    },
    onChangeList: function ({ source, destination }) {
      this.source = source;
      this.destination = destination;
    },
    onApplicationTypeChange(selectedApplicationType) {
      if (this.isProcessing || !selectedApplicationType) {
        return;
      }
      this.isProcessing = true;
      try {
        this.populateDualBoxValues(selectedApplicationType);
      } finally {
        this.isProcessing = false;
      }
    },
    async populateDualBoxValues(selectedApplicationType) {
      return new Promise(async (resolve, reject) => {
        try {
          this.isLoading = true;
          const applicationType = await this.doFindApplicationType(
            selectedApplicationType
          );
          const formDataCalls = [];
          if (applicationType.form_citizen_id) {
            formDataCalls.push(this.doFindForm(applicationType.form_citizen_id))
          }
          if (applicationType.form_staff_id) {
            formDataCalls.push(this.doFindForm(applicationType.form_staff_id))
          }
          const formsData = await Promise.all(formDataCalls);
          const fields = Object.values((formsData[0] ? formsData[0] : []).fields_map.concat((formsData[1] ? formsData[1] : []).fields_map).reduce((acc, item) => {
              if (!excludeColumnTypes.includes(item.type) && !defaultColumns.find(c => c.name === item.name)) {
                acc[item.name] = acc[item.name] || item;
              }
              return acc;
            }, {})
          );
          const flattenColumns = await this.flattenChildFields(
            fields
          );
          this.source = defaultColumns.concat(flattenColumns);
          this.destination = [];
          resolve();
        } catch (error) {
          reject(error);
        } finally {
          this.isLoading = false;
        }
      });
    },
    flattenChildFields(data, parent = "", result = [], depth = 0, maxDepth = 6) {
      if (depth > maxDepth) return result;
      if (Array.isArray(data)) {
        data.forEach((item) =>
          this.flattenChildFields(item, parent, result, depth, maxDepth)
        );
      } else if (typeof data === "object" && data !== null) {
        if (!data.child || data.child.length === 0) {
          const fullName = parent ? `${parent}.${data.name}` : data.name;
          if (fullName) {
            result.push({ name: fullName });
          }
        }
        if (Array.isArray(data.child)) {
          data.child.forEach((child) =>
            this.flattenChildFields(child, data.name || parent, result, depth + 1, maxDepth)
          );
        }
      }
      return result;
    },
  },
};
</script>
